
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import '~@angular/material/theming';

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat-core(); 

// define a real custom palette (using http://mcg.mbitson.com)
$bv-brand: (
    50: #53833B,
    100: #53833B,
    200: #53833B,
    300: #53833B,
    400: #53833B,
    500: #53833B,
    600: #53833B,
    700: #53833B,
    800: #53833B,
    900: #53833B,
    A100: #53833B,
    A200: #53833B,
    A400: #53833B,
    A700: #53833B,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);
$md-ft-primary: (
    50 : #e3ebf4,
    100 : #b9cce3,
    200 : #8baad0,
    300 : #5d88bd,
    400 : #3a6faf,
    500 : #53833B,
    600 : #144e99,
    700 : #11448f,
    800 : #0d3b85,
    900 : #072a74,
    A100 : #a4bcff,
    A200 : #7196ff,
    A400 : #3e71ff,
    A700 : #255eff,
    contrast: (
        50 : #ffffff,
        100 : #ffffff,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$parabola-primary: mat-palette($md-ft-primary);
$parabola-accent: mat-palette($md-ft-primary, A200, A100, A400);

// The warn palette is optional (defaults to red).
$parabola-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$parabola-theme: mat-light-theme((
  color: (
    primary: $parabola-primary,
    accent: $parabola-accent,
    warn: $parabola-warn,
  )
));

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

@import '~@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
     
$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  display-1: mat-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  title: mat-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
  --primary-color: #53833b;
  --primary-lighter-color: #cbdac4;
  --primary-darker-color: #396626;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: #53833b,
  lighter: #cbdac4,
  darker: #396626,
  200: #53833b, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #ffffff;
  --accent-lighter-color: #ffffff;
  --accent-darker-color: #ffffff;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
  main: #ffffff,
  lighter: #ffffff,
  darker: #ffffff,
  200: #ffffff, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);;

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($parabola-theme);

